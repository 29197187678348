export enum SynchronizeName {
  EMPRUNTEUR_INFORMATIONS_SYNC = 'INFORMATIONS_EMPRUNTEUR',
  CO_EMPRUNTEUR_INFORMATIONS_SYNC = 'INFORMATIONS_CO_EMPRUNTEUR',
  BANK_INFORMATIONS_INFORMATIONS_SYNC = 'INFORMATIONS_BANK_INFORMATIONS',
}

export interface ISynchronize {
  name: SynchronizeName;
  response: unknown;
  isSaving: boolean;
  isAttempting: boolean;
  isSuccess: boolean;
  request?: unknown;
  attemptCount: number;
}

export interface IState {
  synchro: ISynchronize[];
}

export interface ISynchronizeResponse<TResponse> extends ISynchronize {
  response: TResponse;
}

export interface ISynchronizeRequest<TRequest> extends ISynchronize {
  request: TRequest;
}

export interface ISynchronizeFullyTyped<TRequest, TResponse>
  extends ISynchronize {
  response: TResponse;
  request: TRequest;
}
