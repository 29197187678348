import { EvidenceType, IEmprunteurRequest } from '@types';
import { ICoEmprunteurRequest } from '@types';

export interface IAddress {
  addressNature?: string;
  additionalAddress?: string;
  city?: string;
  streetAddress?: string;
  zipCd?: string;
}

export interface IContactInformation {
  mobilePhoneNumber?: string;
  fixPhoneNumber?: string;
  email?: string;
}

export enum RoleCd {
  BORROWER = 'TIT',
  COBORROWER = 'CTI',
  PARTNER = 'CNJ',
  COMPTE_JOINT = 'CJ',
}

export interface IInformationPersonnelle {
  first_name: string;
  last_name: string;
  birth_date: string;
  nationality: string;
  title: string;
}

export interface IIdentity {
  birthCity?: string;
  birthCityInseeCd?: string;
  birthCountry?: string;
  birthDepartmentCd?: string;
  birthDt?: string;
  birthName?: string;
  firstName?: string;
  lastName?: string;
  nationalityIsoCd?: string;
  titleCd?: string;
  evidenceSubTypeCd?: string;
  evidenceNb?: string;
  isEditable?: boolean;
}

export interface IDocument {
  evidenceSubTypeCd?: string;
  evidenceTypeCd?: EvidenceType;
  ocrId?: string;
  validityCd?: string;
  evidenceNb?: string;
}

export interface IProfessionalSituation {
  employerName?: string;
  employerZipCode?: string;
  activitySector?: string;
  profession?: string;
  activityType?: string;
  siren?: number;
  employeesNumber?: number;
  workplaceStatusCd?: string;
  professionnalStatusCd?: string;
  beginningEmployerDate?: string;
  contractType?: string;
}

export interface IFamilySituation {
  maritalStatus?: string;
  childrenNb?: string;
}

export interface IHousingSituation {
  housingStatus?: string;
  housingEntryDate?: string;
}

export interface IPerson {
  documents?: IDocument[];
  identity?: IIdentity;
  billingAddressInformation?: IAddress;
  contactInformation?: IContactInformation;
  ocrKo?: string;
  professionalSituation?: IProfessionalSituation;
  familySituation?: IFamilySituation;
  housingSituation?: IHousingSituation;
  role?: RoleCd | string;
}

export interface IState {
  emprunteur: IPerson;
  coemprunteur: IPerson;
}
