import { DependencyList, EffectCallback, useEffect } from 'react';
import { selectConfiguration, useAppSelector } from '@store';

/**
 * Exécute un useEffect à l'initiatilasation ou reprise de l'app/parcours + deps passées en paramètres
 * S'utilise comme un useEffect
 *
 * @param effect premier paramètre de useEffect
 * @param deps second paramètre de useEffect
 * @returns
 */
const useEffectOnInitApp = (
  effect: EffectCallback = () => {},
  deps: DependencyList = []
): void => {
  const { souscriptionId } = useAppSelector(selectConfiguration);

  return useEffect(effect, [, souscriptionId, ...deps]);
};

export default useEffectOnInitApp;
