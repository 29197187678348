import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDocument, IPerson, IState, RoleCd } from './personTypes';

const getInitialState = () => {
  return {
    state: {},
  } as {
    state: IState;
  };
};

// Initial State
export const initialState = getInitialState();

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    updatePerson(personState, action: PayloadAction<Partial<IState>>) {
      personState.state = { ...personState?.state, ...action.payload };
      if (personState?.state?.emprunteur) {
        personState.state.emprunteur = {
          ...personState?.state.emprunteur,
          ...action.payload,
          role: RoleCd.BORROWER,
        };
      }
      if (personState?.state?.coemprunteur) {
        personState.state.coemprunteur = {
          ...personState?.state.coemprunteur,
          ...action.payload,
          role: RoleCd.COBORROWER,
        };
      }
    },
    updatePersonEmprunteur(
      personState,
      action: PayloadAction<Partial<IPerson>>
    ) {
      personState.state.emprunteur = {
        ...personState?.state?.emprunteur,
        ...action.payload,
        role: RoleCd.BORROWER,
      };
    },
    updatePersonCoEmprunteur(
      personState,
      action: PayloadAction<Partial<IPerson>>
    ) {
      personState.state.coemprunteur = {
        ...personState?.state?.coemprunteur,
        ...action.payload,
        role: RoleCd.COBORROWER,
      };
    },
    clear(personState) {
      personState.state = getInitialState().state;
    },
    clearCoEmprunteur(personState) {
      personState.state.coemprunteur = getInitialState().state.coemprunteur;
    },
    updateDocumentsEmprunteur(
      personState,
      action: PayloadAction<Partial<IDocument>>
    ) {
      personState.state.emprunteur = {
        ...personState?.state?.emprunteur,
        documents: [
          ...(personState?.state?.emprunteur?.documents ?? []),
          action.payload,
        ],
        role: RoleCd.BORROWER,
      };
    },
    updateDocumentsCoEmprunteur(
      personState,
      action: PayloadAction<Partial<IDocument>>
    ) {
      personState.state.coemprunteur = {
        ...personState?.state?.coemprunteur,
        documents: [
          ...(personState?.state?.coemprunteur?.documents ?? []),
          action.payload,
        ],
        role: RoleCd.COBORROWER,
      };
    },
    setOcrKo(personState, action: PayloadAction<string>) {
      personState.state.emprunteur = {
        ...personState?.state?.emprunteur,
        ocrKo: action.payload,
      };
    },
  },
});

export const selectPerson = (personState: { person: { state: IState } }) =>
  personState.person.state;

export const {
  updatePerson,
  updatePersonEmprunteur,
  updatePersonCoEmprunteur,
  clear,
  clearCoEmprunteur,
  updateDocumentsEmprunteur,
  updateDocumentsCoEmprunteur,
  setOcrKo,
} = personSlice.actions;

export default personSlice.reducer;
