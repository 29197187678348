const NEED_REDIRECT_AUTHENTICATION = 'need-redirect-authentication';

export const resetAuthenticationRequired = () => {
  sessionStorage.setItem(NEED_REDIRECT_AUTHENTICATION, 'false');
};

export const setAuthenticationRequired = () => {
  sessionStorage.setItem(NEED_REDIRECT_AUTHENTICATION, 'true');
};

export const isAuthenticationRequired = () =>
  sessionStorage.getItem(NEED_REDIRECT_AUTHENTICATION) === 'true';

export const setAuthenticationEnabled = (authenticationEnabled = true) => {
  sessionStorage.setItem(
    'authentication-enabled',
    authenticationEnabled ? 'true' : 'false'
  );
};

export const isAuthenticationEnabled = () =>
  sessionStorage.getItem('authentication-enabled') !== 'false';
