import { useSynchronize } from '@hooks';
import {
  selectOffer,
  selectPerson,
  useAppSelector,
  useSendCoEmprunteurMutation,
  useSendEmprunteurMutation,
} from '@store';
import {
  ICoEmprunteurRequest,
  IEmprunteur,
  ICoEmprunteur,
  IEmprunteurRequest,
  IPerson,
  RoleCd,
  SynchronizeName,
} from '@types';
import { buildEmprunteurBackDataToStoreUtils } from 'containers/Informations/InformationsUtils';

const useBorrowers = () => {
  const { emprunteur, coemprunteur } = useAppSelector(selectPerson);

  const offer = useAppSelector(selectOffer);
  const hasCoborrower = () => offer?.inputBorrowers === '2';

  const validatePerson = ({
    personFromRequest,
    personFromResponse,
  }: {
    personFromRequest?: Partial<IPerson>;
    personFromResponse?: Partial<IPerson>;
  }) => {
    if (!personFromRequest || !personFromResponse) {
      return true;
    }
    return (
      (!personFromRequest.identity?.firstName ||
        personFromRequest.identity?.firstName ===
          personFromResponse.identity?.firstName) &&
      (!personFromRequest.identity?.lastName ||
        personFromRequest.identity?.lastName ===
          personFromResponse.identity?.lastName) &&
      (!personFromRequest.billingAddressInformation?.city ||
        personFromRequest.billingAddressInformation?.city ===
          personFromResponse.billingAddressInformation?.city)
    );
  };

  const validateEmprunteur = ({
    request,
    response,
  }: {
    request?: IEmprunteurRequest;
    response?: IEmprunteur;
  }) => {
    if (!request || !response) {
      return true;
    }
    const personFromRequest =
      buildEmprunteurBackDataToStoreUtils(request?.emprunteur) ?? {};
    const personFromResponse =
      buildEmprunteurBackDataToStoreUtils(response) ?? {};
    return validatePerson({ personFromRequest, personFromResponse });
  };

  const validateCoEmprunteur = ({
    request,
    response,
  }: {
    request?: ICoEmprunteurRequest;
    response?: ICoEmprunteur;
  }) => {
    if (!request || !response) {
      return true;
    }
    const personFromRequest =
      buildEmprunteurBackDataToStoreUtils(request?.coemprunteur) ?? {};
    const personFromResponse =
      buildEmprunteurBackDataToStoreUtils(response) ?? {};
    return validatePerson({ personFromRequest, personFromResponse });
  };

  const validateStoredDataAfterSynchro = ({
    storedData: person,
    response: notFormattedResponse,
  }: {
    storedData: IPerson;
    response: IEmprunteur | ICoEmprunteur;
  }) => {
    const response = buildEmprunteurBackDataToStoreUtils(notFormattedResponse);
    return (
      person?.identity?.firstName === response?.identity?.firstName &&
      person?.identity?.lastName === response?.identity?.lastName &&
      person?.identity?.birthName === response?.identity?.birthName &&
      person?.contactInformation?.email ===
        response?.contactInformation?.email &&
      person?.contactInformation?.mobilePhoneNumber ===
        response?.contactInformation?.mobilePhoneNumber &&
      (person?.role !== RoleCd.BORROWER ||
        person?.billingAddressInformation?.city ===
          response?.billingAddressInformation?.city) &&
      person?.familySituation?.childrenNb ==
        response?.familySituation?.childrenNb
    );
  };

  const {
    send: sendEmprunteur, // Envoi de la modification au backend
    response: responseEmprunteur, // réponse du backend
    refreshSynchro: refreshSynchroEmprunteur, // relance de synchro si nécessaire après chargement de l'app (ex. reprise ping)
    validateSyncEnded: validateSyncEndedEmprunteur, // validation après la synchro terminée
    synchronizedData: synchronizedEmprunteur, // données de synchronisation
  } = useSynchronize({
    synchronizeName: SynchronizeName.EMPRUNTEUR_INFORMATIONS_SYNC,
    getStoredData: () => emprunteur,
    validate: validateEmprunteur,
    validateStoreSynchronization: validateStoredDataAfterSynchro,
    useSendMutation: useSendEmprunteurMutation,
  });

  const {
    send: sendCoEmprunteur, // Envoi de la modification au backend
    response: responseCoEmprunteur, // réponse du backend
    refreshSynchro: refreshSynchroCoEmprunteur, // relance de synchro si nécessaire après chargement de l'app (ex. reprise ping)
    validateSyncEnded: validateSyncEndedCoEmprunteur, // validation après la synchro terminée
    synchronizedData: synchronizedCoemprunteur, // données de synchronisation
  } = useSynchronize({
    synchronizeName: SynchronizeName.CO_EMPRUNTEUR_INFORMATIONS_SYNC,
    getStoredData: () => coemprunteur,
    validate: validateCoEmprunteur,
    validateStoreSynchronization: validateStoredDataAfterSynchro,
    useSendMutation: useSendCoEmprunteurMutation,
  });

  const refreshSynchroBorrowers = () => {
    refreshSynchroEmprunteur();
    refreshSynchroCoEmprunteur();
  };

  const validateBorrower = (role: RoleCd = RoleCd.BORROWER): boolean =>
    role === RoleCd.BORROWER
      ? validateSyncEndedEmprunteur()
      : validateSyncEndedCoEmprunteur();

  return {
    hasCoborrower,
    sendEmprunteur,
    sendCoEmprunteur,
    responseEmprunteur,
    responseCoEmprunteur,
    refreshSynchroBorrowers,
    validateBorrower,
    synchronizedEmprunteur,
    synchronizedCoemprunteur,
  };
};

export default useBorrowers;
