import { WindowWithEnv } from '@types';
import { isAuthenticationEnabled } from '@utils';

export const getBffURL = () =>
  (window as unknown as WindowWithEnv)._env_?.msBffBaseURL + 'souscription/' ??
  '';

export const getBffSimulationURL = () =>
  (window as unknown as WindowWithEnv)._env_?.msBffBaseURL + 'simulation/' ??
  '';

export const getBffMentionLegaleURL = () =>
  (window as unknown as WindowWithEnv)._env_?.baseUrlRetailer ?? '';

export const isDocumentEnabled = () =>
  (window as unknown as WindowWithEnv)._env_?.enableDocument ?? false;

export const getEnv = () =>
  (window as unknown as WindowWithEnv)._env_?.env ?? '';

export const getAuthenticationEnabled = () =>
  ((window as unknown as WindowWithEnv)._env_?.isAuthEnable ||
    process.env.REACT_APP_AUTHENTICATION_ENABLED === 'true') &&
  // On n'est pas sur un parcours apim axway
  isAuthenticationEnabled();

export const getAuthenticationPath = () =>
  (window as unknown as WindowWithEnv)._env_?.authenticationPath;

export const getAuthenticationUri = () =>
  (window as unknown as WindowWithEnv)._env_?.authenticationUri ?? '';

export const getStrictMode = () =>
  !!(window as unknown as WindowWithEnv)._env_?.strictMode;

export const getMsBffBaseURL = () =>
  (window as unknown as WindowWithEnv)._env_?.msBffBaseURL;

export const getMsBffDomainPrefix = () =>
  (window as unknown as WindowWithEnv)._env_?.msBffDomainPrefix;

export const getIhmVersion = () => {
  try {
    const metas = document.getElementsByTagName('meta') ?? [];
    let version = '';
    for (const i in metas) {
      const element = metas[i];
      if (element.name === 'version') {
        version = element.content;
        break;
      }
    }
    return version;
  } catch (e) {
    console.log('Erreur à la récupération de la version IHM => ', e);
    return '';
  }
};
