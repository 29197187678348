import {
  selectConfiguration,
  selectError,
  selectSwitchDevice,
  setError,
  updateSwitchDevice,
  useAppDispatch,
  useAppSelector,
  useLazyGetIdDebranchementQRQuery,
} from '@store';
import { useEffect, useState } from 'react';
import { EModalState } from './ModalQRCode/ModalQRCode';
import {
  useListenerSwitchMobile,
  useParcoursType,
  usePersistingState,
} from '@hooks';
import {
  EModalErrorState,
  EventSwitchDevice,
  FINAL_EVENTS_SWITCH_DEVICE,
} from '@types';
import { getEnv } from '@utils';
import useSynchronizeDocuments from './useSynchronizeDocuments';

const useSwitchDevice = () => {
  const { souscriptionId, initAppUrlParams } =
    useAppSelector(selectConfiguration);
  const { code: errorCode } = useAppSelector(selectError);
  const {
    isListenerTriggering,
    isListenerInitialized,
    isUploadSwitchDeviceKO,
    isTechnicalErrorSent,
    isFinishUploadMobile,
  } = useAppSelector(selectSwitchDevice);
  const [urlQRCode, setUrlQRCode] = useState('');
  const [isModalQRCodeVisible, setIsModalQRCodeVisible] = useState(false);
  const [isLoadingQRCodeButton, setIsLoadingQRCodeButton] = useState(false);
  const [ModalErrorState, setModalErrorState, getModalErrorState] =
    usePersistingState<EModalErrorState | undefined>(
      'modalErrorState',
      undefined
    );
  const dispatch = useAppDispatch();
  const [triggerGetIdDebranchementQRCode, resultGetIdDebranchementQRCode] =
    useLazyGetIdDebranchementQRQuery();

  const { sendDocumentsSynchronization } = useSynchronizeDocuments();

  const [isSwitchMobileConnected, setIsSwitchMobileConnected] = useState(false);
  const [modalState, setModalState] = usePersistingState<EModalState>(
    'modalSwitchDeviceState',
    EModalState.INITIAL
  );
  const { isParcoursNominal, isParcoursSwitchDevice } = useParcoursType();

  const {
    triggerListenerSwitchMobile,
    responseListenerSwitchMobileConsult,
    sendAddEventSwitchMobile,
    interrupteListener,
    responseSendAjoutSwitchDevice,
  } = useListenerSwitchMobile();

  useEffect(() => {
    if (!isParcoursSwitchDevice()) {
      return;
    }
    if (!isListenerInitialized) {
      dispatch(
        updateSwitchDevice({
          isListenerInitialized: true,
        })
      );
      sendAddEventSwitchMobile(EventSwitchDevice.CONNEXION);
    }
    if (
      !isListenerTriggering &&
      !isUploadSwitchDeviceKO &&
      !isFinishUploadMobile
    ) {
      triggerListenerSwitchMobile();
    }
  }, [, isParcoursSwitchDevice(), responseSendAjoutSwitchDevice]);

  useEffect(() => {
    responseListenerSwitchMobileConsult?.forEach((evenement) => {
      if (evenement?.evenementRealise === EventSwitchDevice.CONNEXION) {
        setIsSwitchMobileConnected(true);
        setModalState(EModalState.SWITCH_MOBILE_CONNECTED);
      }
      if (!FINAL_EVENTS_SWITCH_DEVICE.includes(evenement?.evenementRealise)) {
        return;
      }
      if (isParcoursSwitchDevice()) {
        dispatch(
          updateSwitchDevice({
            isUploadSwitchDeviceKO: true,
          })
        );
        endParcoursReInit();
        return;
      }
      switch (evenement?.evenementRealise) {
        case EventSwitchDevice.ANALYSE_OCR_KO:
          setModalState(EModalState.UNAVAIABLE_SERVICE_OCR);

          break;
        case EventSwitchDevice.INACTIVITE:
          setIsModalQRCodeVisible(false);
          dispatch(
            setError({
              code: 'timeOut',
            })
          );

          break;
        case EventSwitchDevice.SERVICE_OCR_KO:
          setIsModalQRCodeVisible(false);
          setModalErrorState(EModalErrorState.UNAVAILABLE_SERVICE);

          break;
        case EventSwitchDevice.TECHNICAL_ERROR:
          setIsModalQRCodeVisible(false);
          dispatch(
            setError({
              code: 'ERREUR_INCONNUE',
            })
          );

          break;
        default:
          setModalState(EModalState.TELECHARGEMENT_REUSSI);
          sendDocumentsSynchronization();
          dispatch(
            updateSwitchDevice({
              isListenerTriggering: false,
              isListenerInitialized: false,
            })
          );
          break;
      }
    });
  }, [responseListenerSwitchMobileConsult]);

  useEffect(() => {
    if (!urlQRCode || !isParcoursNominal() || getEnv() === 'prod') {
      return;
    }
    console.log('URL Debranchement QRcode => ', urlQRCode);
  }, [urlQRCode]);

  useEffect(() => {
    if (
      !resultGetIdDebranchementQRCode?.data ||
      resultGetIdDebranchementQRCode?.status !== 'fulfilled' ||
      !initAppUrlParams
    ) {
      return;
    }
    sendAddEventSwitchMobile(EventSwitchDevice.CREATION);
    setUrlQRCode(
      window.location.origin +
        '/' +
        (window.location.hash ?? '').split('?')[0] +
        '?' +
        initAppUrlParams +
        '&isDebranchementQRCode=true' +
        '&switchDeviceId=' +
        resultGetIdDebranchementQRCode?.data
    );
    setIsLoadingQRCodeButton(false);
    setIsModalQRCodeVisible(true);
  }, [resultGetIdDebranchementQRCode]);

  useEffect(() => {
    if (
      !responseSendAjoutSwitchDevice?.isSuccess ||
      responseSendAjoutSwitchDevice?.originalArgs?.body?.evenementRealise !==
        EventSwitchDevice.CREATION ||
      isParcoursSwitchDevice()
    ) {
      return;
    }
    triggerListenerSwitchMobile();
  }, [responseSendAjoutSwitchDevice]);

  useEffect(() => {
    if (!isParcoursSwitchDevice() || isTechnicalErrorSent || !errorCode) {
      return;
    }
    dispatch(
      updateSwitchDevice({
        isTechnicalErrorSent: true,
      })
    );
    sendAddEventSwitchMobile(EventSwitchDevice.TECHNICAL_ERROR);
  }, [errorCode]);

  const onClickFinishButton = () => {
    sendAddEventSwitchMobile(EventSwitchDevice.FERMETURE);
    dispatch(
      updateSwitchDevice({
        isFinishUploadMobile: true,
      })
    );
  };

  const onClickQRCodeButton = () => {
    if (!souscriptionId) {
      return;
    }
    triggerGetIdDebranchementQRCode(souscriptionId);
    setIsLoadingQRCodeButton(true);
  };

  const onClickArretParcoursSwitchDevice = () => {
    sendDocumentsSynchronization();
    sendAddEventSwitchMobile(EventSwitchDevice.FERMETURE);
    interrupteListener();
    endParcoursReInit();
  };

  const onClickCancel = () => {
    setModalState(
      isSwitchMobileConnected
        ? EModalState.SWITCH_MOBILE_CONNECTED
        : EModalState.INITIAL
    );
  };

  const onClickTelechargementReussi = () => {
    endParcoursReInit();
  };

  const endParcoursReInit = () => {
    setIsModalQRCodeVisible(false);
    setModalState(EModalState.INITIAL);
    setIsSwitchMobileConnected(false);
    dispatch(
      updateSwitchDevice({
        isListenerTriggering: false,
        isListenerInitialized: false,
      })
    );
  };

  return {
    urlQRCode,
    modalState,
    setModalState,
    isModalQRCodeVisible,
    isLoadingQRCodeButton,
    onClickFinishButton,
    onClickQRCodeButton,
    onClickArretParcoursSwitchDevice,
    onClickCancel,
    onClickTelechargementReussi,
    getModalErrorState,
  };
};

export default useSwitchDevice;
