import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IState,
  ISynchronize,
  ISynchronizeFullyTyped,
  SynchronizeName,
} from './synchronizeTypes';

const getInitialSynchros = () => {
  const initialSynchros: ISynchronize[] = [];
  Object.values(SynchronizeName).forEach((synchronizeName) => {
    initialSynchros.push({
      name: synchronizeName,
      isSuccess: false,
      attemptCount: 0,
      response: null,
      isSaving: false,
      isAttempting: false,
    });
  });
  return initialSynchros;
};

const getInitialState = () => {
  return { state: { synchro: getInitialSynchros() } } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const getCurrentSynchroIndex = ({
  name,
  synchros,
}: {
  name: SynchronizeName;
  synchros: ISynchronize[];
}): number | undefined => {
  if ((name?.length ?? 0) < 1) {
    return;
  }
  const currentSynchros = synchros ?? [];
  for (const currentSynchroIndex in currentSynchros) {
    if (!currentSynchros[currentSynchroIndex]?.name) {
      continue;
    }
    if (currentSynchros[currentSynchroIndex]?.name === name) {
      return +currentSynchroIndex;
    }
  }
};

const synchronizeSlice = createSlice({
  name: 'synchronize',
  initialState,
  reducers: {
    update(synchronizeState, action: PayloadAction<Partial<ISynchronize>>) {
      if (!action?.payload?.name) {
        return;
      }
      if (!synchronizeState?.state?.synchro) {
        synchronizeState.state.synchro = [];
      }
      const currentSynchros = synchronizeState?.state?.synchro;
      let isUpdated = false;
      for (const currentSynchroIndex in currentSynchros) {
        if (!currentSynchros[currentSynchroIndex]?.name) {
          continue;
        }
        if (
          currentSynchros[currentSynchroIndex]?.name === action?.payload?.name
        ) {
          currentSynchros[currentSynchroIndex] = {
            ...currentSynchros[currentSynchroIndex],
            ...action?.payload,
          };
          isUpdated = true;
          break;
        }
      }
      if (!isUpdated) {
        currentSynchros.push(action?.payload as ISynchronize);
      }
    },
    resetSynchronize<TRequest, TStoredData>(
      synchronizeState: { state: IState },
      action: PayloadAction<{
        synchronizeName: SynchronizeName;
        request?: TRequest;
        response?: TStoredData;
      }>
    ) {
      if (!action?.payload?.synchronizeName) {
        return;
      }
      const synchros = synchronizeState?.state?.synchro ?? [];
      const currentSynchroIndex = getCurrentSynchroIndex({
        name: action?.payload?.synchronizeName,
        synchros,
      });
      if (currentSynchroIndex === undefined) {
        return;
      }
      const currentSynchro = synchros[currentSynchroIndex];
      const newSynchro = {
        name: action?.payload?.synchronizeName,
        attemptCount: currentSynchro?.isSuccess
          ? 1
          : (currentSynchro?.attemptCount ?? 0) + 1,
        response: action?.payload?.response,
        isSaving: true,
        isAttempting: true,
        isSuccess: false,
        request: action?.payload?.request,
      };
      synchros[currentSynchroIndex] = newSynchro;
    },
    responseSynchronize<TRequest, TResponse>(
      synchronizeState: { state: { synchro: ISynchronize[] } },
      action: PayloadAction<
        Partial<ISynchronizeFullyTyped<TRequest, TResponse>>
      >
    ) {
      if (!action?.payload?.name) {
        return;
      }
      const synchros = synchronizeState?.state?.synchro;
      const currentSynchroIndex = getCurrentSynchroIndex({
        name: action?.payload?.name,
        synchros,
      });
      if (currentSynchroIndex === undefined) {
        return;
      }
      synchros[currentSynchroIndex] = {
        ...synchros[currentSynchroIndex],
        isSaving: false,
        ...action?.payload,
      } as ISynchronizeFullyTyped<TRequest, TResponse>;
    },
    clear(synchronizeState) {
      synchronizeState.state = getInitialState().state;
    },
  },
});

export const selectSynchronize = (synchronizeState: {
  synchronize: { state: IState };
}) => synchronizeState.synchronize.state?.synchro;

export const { resetSynchronize, responseSynchronize, clear } =
  synchronizeSlice.actions;

export default synchronizeSlice.reducer;
